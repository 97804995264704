.Hero {
  height: calc(100vh - 70px);
  background: var(--header-mobi-color);
  position: relative;
  color: #fefefe;
  overflow: hidden;
  overflow: clip;
}
.Hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.6) 80%,
    rgba(0, 0, 0, 0.3)
  );
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  object-fit: cover;
  /*transition: .4s ease;*/
  /*opacity: 0;*/
}

/*.bg[data-active-image-index="0"] .bgImage1 {*/
/*  opacity: 1;*/
/*}*/

/*.bg[data-active-image-index="1"] .bgImage2 {*/
/*  opacity: 1;*/
/*}*/

/*.bg[data-active-image-index="2"] .bgImage3 {*/
/*  opacity: 1;*/
/*}*/

.HeroContent {
  position: relative;
  z-index: 1;
}
.Top {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
}

.Meteo {
  position: relative;

  display: flex;
  align-items: center;
  gap: 1rem;

  margin-top: 30px;
  padding: .5rem 1rem;

  border-radius: 6px;

  overflow: hidden;
}
.Meteo::after {
  content: '';
  position: absolute;
  inset: 0;

  background: linear-gradient(hsla(0deg, 100%, 100%, .04), hsla(0deg, 100%, 100%, .1));

  animation: meteoBg 4s infinite alternate 2s;
}
.Meteo::before {
  content: '';
  position: absolute;
  inset: 0;

  background: linear-gradient(45deg, hsla(0deg, 100%, 100%, .1), rgba(112, 166, 217, .12));

  animation: meteoBg 4s infinite alternate;
}
@keyframes meteoBg {
  50% {
    opacity: 0;
  }
}

.MeteoIcon {
  background: url("./Шкала_погоды.png") no-repeat;
  background-size: contain;
  font-size: 30px;
  width: 130px;
  height: 77px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 20px;
}
.MeteoInfo {
  display: flex;
}
.MeteoValues {
  display: flex;
  flex-direction: column;
}
.MeteoValue {
  align-self: flex-end;
  margin-left: 1rem;
}

.Content {
  margin-left: auto;
  width: max-content;
  margin-right: 105px;
}
.Text {
  font-size: 45px;
  margin-bottom: 80px;
  font-weight: bold;
}
.Button {
  background: transparent;
  font-size: 25px;
  color: var(--green-color);
  padding: 12px 18px;
  border: 2px solid var(--green-color);
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;

  transition: border-color 128ms, color 128ms;
}

.Button:hover {
  border: 2px solid var(--green-color-hovered);
  color: var(--green-color-hovered);
}

/*METEO FRAME DEV*/
.dayTimeFrame {
  position: relative;

  width: 168px;
  height: 92px;

  overflow: hidden;
}
.dayTimeScale {
  position: absolute;
  top: 0;
  left: 50%;

  transform: translate(-50%, 12px);

  height: 136px;
  width: 136px;

  border: 9px solid white;
  border-radius: 50%;
}
.dayTimeScale_dark {
  border: 10px solid #9BA6B8;
}
.scaleSun {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:
          translate(-50%, -50%)
          rotate(-8deg);

  width: 100%;
  height: 100%;

  transform-origin: center;
  transition: transform 792ms;
}
.scaleSun:before {
  content: "";

  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(calc(-50% - 5px), -50%);

  width: 20px;
  height: 20px;

  border-radius: 50%;
  background: #EFDD87;
  box-shadow: 0 0 5px white,
              0 0 8px #D9C357,
              0 0 15px #D68A32,
              0 0 8px red;

  animation: sunLight 7s infinite alternate;
}
@keyframes sunLight {
  33% {
    box-shadow:
              0 0 8px white,
              0 0 15px #D9C357,
              0 0 12px #D68A32,
              0 0 17px red;

  }
  66% {
    box-shadow:
              0 0 3px white,
              0 0 16px #D9C357,
              0 0 8px #D68A32,
              0 0 18px white,
              0 0 10px red;
    background: #F4ECB3;

  }
  100% {
    box-shadow:
              0 0 9px white,
              0 0 18px #D9C357,
              0 0 12px #D68A32,
              0 0 18px red,
              0 0 12px red;
    background: #EFD0B8;
  }
}
.moonWrapper {
  position: absolute;
  left: 50%;
  top: 28%;
  transform: translateX(-50%);
}
.moonBackground {
  position: absolute;
  top: 46%;
  left: 46%;
  transform: translate(-50%, -50%);

  width: 23px;
  height: 23px;

  border-radius: 50%;

  box-shadow:
          0 0 3px white,
          0 0 12px white,
          0 0 24px white,
          0 0 36px white;
  animation: moonLight 7s infinite alternate;
}
@keyframes moonLight {
  33% {
    box-shadow:
            0 0 3px #987AD9,
            0 0 12px #76B0D9,
            0 0 24px white;

  }
  66% {
    box-shadow:
            0 0 3px white,
            0 0 12px white,
            0 0 24px #76B0D9,
            0 0 36px #987AD9;

  }
  100% {
    box-shadow:
            0 0 12px #987AD9,
            0 0 24px white,
            0 0 32px #76B0D9;
  }
}
.moonIcon {
  position: relative;

  fill: #BFCFEE;
  background: transparent;

  overflow: visible;
}

.meteoAirTemp {
  position: absolute;
  bottom: 0;

  width: 100%;

  text-align: center;
  font-size: 1.8rem;
}


@media screen and (max-width: 768px) {
  .Meteo {
    margin: 0;
  }

  .Content {
    width: auto;
    margin: 0;
    padding: 0 20px;
  }

  .Text {
    font-size: 40px;
  }
}

@media screen and (max-width: 680px) {
  .Hero {
    height: 90vh;
    padding-bottom: 15px;
  }
  .Top {
    display: flex;
    flex-direction: column-reverse;
  }
  .Meteo {
    margin-top: 1rem;
  }
  .MeteoInfo {
    margin-left: auto;
  }
}

@media screen and (max-width: 479px) {
  .MeteoIcon {
    font-size: 20px;
    width: 100px;
    height: 60px;
  }

  .Meteo {
    gap: 15px;
  }

  @media screen and (max-width: 360px) {
    .Top {
      padding: 20px 10px;
    }

    .MeteoIcon {
      font-size: 18px;
      width: 80px;
      height: 50px;
    }
    .Meteo {
      gap: 10px;
    }

    .Text {
      font-size: 28px;
      margin-bottom: 40px;
    }
  }
}
