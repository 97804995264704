.SwitchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Switch {
  background-color: var(--bg-color);
  color: var(--text-color);
  display: flex;
  border-radius: 20px;
  width: 75px;
  height: 33px;
  font-size: 12px;
  align-items: center;
  padding: 0 5px;
  gap: 10px;
  cursor: pointer;
  text-align: right;
}
.SwitchTitle {
  color: #fefefe;
  margin-bottom: 15px;
}
.Icon {
  width: 25px;
  height: 25px;
  background-color: #029fe2;
  border-radius: 50%;
}

@media screen and (max-width: 680px) {
  .SwitchContainer {
    flex-direction: row;
    align-items: center;
  }
  .SwitchTitle {
    margin: 0 1rem 0 0;
  }
}

@media screen and (max-width: 360px) {
  .SwitchTitle {
    display: none;
  }

  .State {
    font-size: 10px;
  }

  .Switch {
    gap: 5px;
    width: 66px;
    height: 33px;
  }
}
