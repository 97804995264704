.Section {
  background-color: var(--bg-color);
  color: var(--text-color);
  min-height: 100vh;
  padding-top: 50px;
}
.SectionContainer {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .SectionContainer {
    flex-direction: column;
  }
}
.SliderItem {
  height: 100%;
  width: 100%;
}
.Title {
  font-size: 35px;
  color: var(--blue-light);
  text-align: right;
  font-weight: bold;
  padding-left: 15px;
  width: 50%;
}
.Title a {
  color: var(--blue-light);
  text-decoration: underline;

  transition: color 92ms;
}
.Title a:hover {
  color: var(--blue-hovered);
}

.Left {
  width: 45%;
  height: 100%;
  padding-left: 15px;
  padding-bottom: 60px;
}

.LeftContent {
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.Date {
  color: var(--green-color);
  margin-top: 70px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 1.4rem;
  text-align: center;
}

.Subtitle {
  margin-top: 1rem;
  font-size: 1.65rem;
  text-decoration: underline;
  color: var(--text-color);
}

.Text {
  flex-grow: 1;
  margin: 20px 16px 0 0;
  max-height: 60vh;
  overflow: auto;
  font-size: 1.25rem;
  white-space: pre-wrap;
}

.Text a {
  text-decoration: underline;
}

.Text::-webkit-scrollbar {
   width: 12px;
 }
.Text::-webkit-scrollbar-track {
   background: transparent;
 }
.Text::-webkit-scrollbar-thumb {
   background: var(--text-color);
 }
/* Buttons */
.Text::-webkit-scrollbar-button:single-button {
  background-color: transparent;
  display: block;
  border-style: solid;
  height: 12px;
  width: 12px;
}
/* Up */
.Text::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent var(--text-color) transparent;
}
/* Down */
.Text::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: var(--text-color) transparent transparent transparent;
}

@media (max-width: 992px) {
  .Text {
    align-items: flex-start;
    height: 37vh;
    overflow: auto;
    font-size: 1rem;
  }
}

.Right {
  width: 50%;
  height: 100%;
  margin-top: 128px;
  padding-bottom: 40px;
  padding-right: 40px;
}

.RightContent {
  max-height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mainImageContainer {
  padding: 8px;
  width: 100%;
  min-height: 30vh;
}

.moreImagesContainer {
  display: flex;
}
.moreImagesContainer a {
  max-width: 30%;
  margin: 0 0 8px 8px;
}
.moreImagesContainer img {
  width: 100%;
}
.RightContent::after{
  position: absolute;
  content: '';
  bottom: -6px;
  width: 100%;
  height: 4px;
  background: var(--text-color);
}
.RightContent::-webkit-scrollbar {
  width: 12px;
}
.RightContent::-webkit-scrollbar-track {
  background: transparent;
}
.RightContent::-webkit-scrollbar-thumb {
  background: var(--text-color);
}
/* Buttons */
.RightContent::-webkit-scrollbar-button:single-button {
  background-color: transparent;
  display: block;
  border-style: solid;
  height: 12px;
  width: 12px;
}
/* Up */
.RightContent::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent var(--text-color) transparent;
}
/* Down */
.RightContent::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 6px 6px 0 6px;
  border-color: var(--text-color) transparent transparent transparent;
}

.Triangle {
  position: absolute;
  width: 150px;
  bottom: -2px;
  left: 0;
}

.Image {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 991px) {
  .Triangle {
    display: none;
  }

  .SliderItem {
    height: 350px;
  }

  .Title {
    text-align: left;
    width: 50%;
  }

  .Section {
    height: auto;
  }
  .SectionContainer {
    display: block;
  }

  .Left {
    width: 100%;
    padding-left: 115px;
  }

  .LeftContent {
    width: 75%;
  }

  .Right {
    width: 100%;
  }

  .Subtitle {
    margin-bottom: 40px;
  }

  .Image {
    width: 100%;
    height: 30vh;
    position: relative;
    left: 50vw;
    transform: translateX(-50%) translateY(-80px);
  }
}

@media screen and (max-width: 768px) {
  .Title {
    font-size: 28px;
    width: 100%;
  }
  .RightContent::after {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .Title {
    font-size: 20px;
  }

  .Left {
    padding-left: 30px;
  }

  .LeftContent {
    width: 86%;
  }

  .Subtitle {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .Date {
    margin-top: 0;
  }
}
